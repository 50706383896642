.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  margin-top: 24px;
  color: var(--pagination);
  .select::before {
    border-bottom: none;
  }

  .pageSelector {
    display: flex;
    align-items: center;
    .arrowButton {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--border_arrow_button);
      border-radius: 10px;
      margin: 0 5px;
      background: var(--whte);
      padding: 8px;
      width: 32px;
      height: 32px;
      cursor: pointer;
      svg {
        width:  4.73px;
        height: 10.56px;
      }
    }
    .numberButton {
      min-width: 20px;
      border: none;
      background: var(--whte);
      cursor: pointer;
    }
    .activeButton {
      border: none;
      background: var(--whte);
      color: var(--button_primary);
    }
  }
}
