.fc
  font-family: 'Plus Jakarta Sans'
  .fc-header
    flex-wrap: wrap
    gap: 12px
  .fc-header-toolbar
    .fc-toolbar-chunk
      & > div
        display: flex
        align-items: center
        .fc-toolbar-title
          font-weight: 600
          font-size: 24px
          margin-right: 12px
          cursor: pointer
        .fc-prev-button, .fc-next-button
          background: transparent
          border: none
          &:focus
            box-shadow: none
          .fc-icon
            &:before
              color: #54577A

      .fc-button-group
        gap: 10px
        .fc-button
          padding: 8px 16px
          height: 40px
          border-radius: 10px
          font-weight: 600
          font-size: 16px
          color: #54577A
          background: transparent
          border: none
          &:focus
            box-shadow: none
        .fc-button-active
          background: #EBEDFA
          color: #030410

  table.fc-scrollgrid
    table
      thead
        th.fc-day
          height: 68px
          color: #54577A
      tr
        td
          border-color: #DCE4FF
          height: 36px
          white-space: nowrap
          .fc-event-main
            border-radius: 8px
          .fc-event-time, .fc-event-separator, .fc-event-case-count
            color: #54577A
            font-size: 12px
          .fc-event-title
            font-size: 16px
            font-weight: 500
            color: #030410
            text-overflow: ellipsis
        td.fc-timegrid-slot-label:first-child
          padding-left: 32px
          border-bottom: none
          border-top: none
        td.fc-timegrid-slot-label.fc-timegrid-slot-minor:first-child:after
          content: ''
          display: block
          position: absolute
          height: 1px
          width: 20px
          background: #DCE4FF
          bottom: 0
          right: 0
        td.fc-timegrid-slot-label.fc-scrollgrid-shrink
          transform: translate(-32px, 50%)
          & *
            color: #54577A
            font-size: 16px
            line-height: 16px
            font-weight: 500
            text-transform: uppercase
        td.fc-timegrid-slot-minor
          border: none
          border-left: 1px solid #DCE4FF

  .fc-timegrid-col.fc-day-today, .fc-daygrid-day.fc-day-today
    background: transparent


.fc-theme-standard .fc-scrollgrid
  border: none

.fc-timegrid-event, .fc-timegrid-more-link
  border-radius: 8px
  padding: 8px

.fc-header-day-of-week
  font-size: 16px
  font-weight: 500
.fc-header-day-of-month
  font-size: 24px
  font-weight: 600
  height: 40px
  width: 40px
  line-height: 40px
.fc-day-today
  .fc-header-day-of-month
    border-radius: 50%
    background-color: #546FFF
    color: #fff
  .fc-daygrid-day-top
    height: 28px
    padding-top: 2px
    padding-left: 2px
  .fc-daygrid-day-number
    display: flex
    align-items: center
    justify-content: center
    padding: 0
    height: 24px
    width: 24px
    border-radius: 50%
    color: #fff
    background: #546FFF

.fc-timeGridWeek-view
  th.fc-day, th.fc-timegrid-axis
    border: none

.fc-event
  cursor: pointer

.fc-event-separator
  margin: 0 6px

.fc-event-case-count
  margin-right: 2px

.fc-event-selected, .fc-event:focus
  box-shadow: none

.fc-event-selected::after, .fc-event:focus::after
  background: none

.fc-dayGridMonth-view
  .fc-daygrid-day-top
    flex-direction: row
    font-size: 12px
    color: #54577A
  .fc-event-time
    display: none
  .fc-header-day-of-month
    display: none
  table.fc-scrollgrid table thead th.fc-day
    height: 32px
    border-left: none
    border-right: none
  .fc-event
    border-radius: 8px
    height: 32px
    padding: 4px 8px
    font-size: 16px
    font-weight: 500
  .fc-daygrid-block-event .fc-event-time, .fc-daygrid-block-event .fc-event-title
    padding: 0
