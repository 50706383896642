$colors: (
        'white': #FFFFFF,
        'black': #000000,
        // Buttons
        'button_hover': #3D53DB,
        'button_pressed': #2A3BB7,
        'button_primary': #546FFF,
        'button_disabled': #98ABFF,
        'button_enabled': #000000,
        // Text
        'text_primary': #030410,
        'text_secondary': #54577A,
        'text_disabled': #EBEDFA,
        // Border
        'border_line': #DCE4FF,
        'border_focused': #BAC8FF,
        'border_select': #6F6F6F,
        'border_arrow_button': #C2C6E8,
        // Table
        'table_bg': #EBEDFA,
        'table_color': #8E92BC,
        'table_border': #E9EDF5,
        // Pagination
        'pagination': #687182,
        "alert": #DB2719,
        // Shadow
        'box_blur': rgba(106, 114, 142, 0.1),
        'box_accent': rgba(105, 129, 216, 0.1),
        'box_contrast': rgba(69, 80, 121, 0.13),

);

@function HexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}

:root {
  @if $colors {
    @each $key, $value in $colors {
      --#{$key}: #{$value};
      --#{$key}-rgb: #{HexToRGB($value)};
    }
  }
}