.text_input{
    height: 48px;
    border-radius: 10px;
    padding: 12px;
    box-sizing: border-box;
    background-color: var(--white);
    border: 1px solid var(--border_line);
    font-weight: 500;
    font-size: 16px;

    &:active{
      background-color: var(--white);
      border: 1px solid var(--button_primary);
      border-radius: 10px;
    }

    &:focus{
      background: var(--white);
      border: 1px solid var(--border_focused);
    }
    &:hover{
      background: var(--white);
      border: 1px solid var(--button_primary);
      border-radius: 10px;
    }
  
    &:disabled{
      background: var(--white);
      border: 1px solid var(--border_line);
      border-radius: 10px;
    }

  }

  .read_text_input {
    height: 48px;
    border-radius: 10px;
    padding: 0;
    box-sizing: border-box;
    background-color: var(--white);
    border: 0;
    font-weight: 500;
    font-size: 16px;

    &:active{
      background-color: var(--white);
    }

    &:focus,
    &:focus-visible {
      background: var(--white);
      border: 0;
      outline: none;
    }
    &:hover{
      background: var(--white);
    }
  
    &:disabled{
      background: var(--white);
    }
  }
