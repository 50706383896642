.dropdown_container{
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;


  label{
    padding: 8px 3px 8px 16px;
    color: var(--text-secondary);
  }

  select{
    height: 100%;
    margin-left: 8px;
    font-size: 18px;
    padding: 8px 16px 8px 0;
    border: 1px solid  rgba(var(--border_select-rgb), 0);
    border-radius: 10px;
  }

  select:hover{
    border: 1px solid  var(--button_hover);
    border-radius: 10px;
  }

  select:focus{
    border: 1px solid  var(--button_enabled);
    border-radius: 10px;
  }

}
