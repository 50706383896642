.main_container{
  display: flex; 
  flex-direction: column;
  align-items: center;
}

.buttons_container{
  display: flex;
  margin-top: 4px;
  align-items: center;
}

