.loaderContainer {
  width: fit-content;
  margin: auto !important;
  min-height: 406px;
  display: flex;
  align-items: center;
}

.loader {
  display: block;
  height: 45px;
  width: 45px;
  border: 2px var(--white) solid;
  border-top-color: var(--black);
  border-right-color: var(--black);
  border-bottom-color: var(--black);
  border-radius: 50%;
  -webkit-animation: loader 2s infinite linear;
  animation: loader 2s infinite linear;
}

.loader:before {
  content: '';
  position: absolute;
  top: 7px;
  right: 7px;
  bottom: 7px;
  left: 7px;
  border: 2px solid transparent;
  border-radius: 50%;
  border-top-color: var(--black);
  border-left-color: var(--black);
  border-bottom-color: var(--black);
  -webkit-animation: loader 1s linear infinite;
  animation: loader 1s linear infinite;
}

.loader:after {
  content: '';
  position: absolute;
  top: 15px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  border: 2px solid transparent;
  border-radius: 50%;
  border-top-color: var(--black);
  border-left-color: var(--black);
  -webkit-animation: loader 1.5s linear infinite;
  animation: loader 1.5s linear infinite;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
