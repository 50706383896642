.add_button {
  background: var(--button_primary);
  padding: 8px 16px;
  border-radius: 10px;
  border: none;
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  cursor: pointer;
  span {
    margin-left: 11px;
    font-size: 12px;
    font-weight: 600;
  }

  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
