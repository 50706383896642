.page_container {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 32px 0 0;
  flex-grow: 1;

  .bar {
    display: flex;
    justify-content: space-between;

    .navigation {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: -0.01em;
      color: var(--text_secondary);
      margin-bottom: 32px;
      padding: 0 32px;

      > div:not(:first-child),
      > svg {
        margin-left: 8px;
      }
    }

    .actions {
      display: flex;
      align-items: center;
      padding: 0 32px;
    }
  }

  .children_box {
    height: 100%;
  }
}
