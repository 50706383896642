@import 'scss/variables';

* {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-style: normal;
  font-size: 14px;
  letter-spacing: -0.02em;
}

em,
em * {
  font-style: italic;
}

.quill {
  width: 100%;
  font-size: 16px;
}

.quill .ql-editor *,
.quill.ql-editor * {
  font-size: 16px;
}

.quill .ql-editor a,
.quill.ql-editor a {
  font-size: 12px;
}

.visibleToolbar .ql-toolbar {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.hiddenToolbar .ql-toolbar {
  display: none;
}

.hiddenToolbar .ql-toolbar.ql-snow+.ql-container.ql-snow {
  border-top: 1px solid #ccc;
  min-height: 200px;
  border-radius: 4px;
}

.visibleToolbar .ql-toolbar.ql-snow+.ql-container.ql-snow {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  min-height: 200px;
}

.visibleToolbar.note .ql-toolbar.ql-snow+.ql-container.ql-snow {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  min-height: 44px;
}

.hiddenToolbar.note .ql-toolbar.ql-snow+.ql-container.ql-snow {
  min-height: 44px;
}

h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 56px;
  letter-spacing: -0.02em;
}

h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  margin: 0;
  padding: 0;
}

h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  margin: 0;
  padding: 0;
}

h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
