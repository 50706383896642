.tableWrapper {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  width: 100%;
}

.tableContainer {
  width: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--border_line);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--table_color);
  }
  table {
    width: 100%;
    border-collapse: collapse;
    thead {
      text-align: left;
      background-color: var(--table_bg);
      color: var(--table_color);
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      tr,
      th {
        padding: 8px;
        text-align: left;
        border-bottom: 1px solid var(--table_border);
        color: #54577a;
        font-size: 16px;
        font-weight: 600;
      }
    }

    tbody {
      tr,
      td {
        padding: 6px 8px;
        text-align: left;
        border-bottom: 1px solid var(--table_border);
        box-sizing: border-box;
        height: 48px;
        font-size: 16px;
        font-weight: 500;
        color: var(--text_primary);
        a {
          font-size: inherit;
          font-weight: inherit;
        }
      }
    }
  }
}

.loaderContainer {
  width: fit-content;
  margin: auto !important;
  min-height: 371px;
  display: flex;
  align-items: center;
}

.loader {
  display: block;
  height: 45px;
  width: 45px;
  border: 2px var(--white) solid;
  border-top-color: var(--black);
  border-right-color: var(--black);
  border-bottom-color: var(--black);
  border-radius: 50%;
  -webkit-animation: loader 2s infinite linear;
  animation: loader 2s infinite linear;
}

.loader:before {
  content: "";
  position: absolute;
  top: 7px;
  right: 7px;
  bottom: 7px;
  left: 7px;
  border: 2px solid transparent;
  border-radius: 50%;
  border-top-color: var(--black);
  border-left-color: var(--black);
  border-bottom-color: var(--black);
  -webkit-animation: loader 1s linear infinite;
  animation: loader 1s linear infinite;
}

.loader:after {
  content: "";
  position: absolute;
  top: 15px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  border: 2px solid transparent;
  border-radius: 50%;
  border-top-color: var(--black);
  border-left-color: var(--black);
  -webkit-animation: loader 1.5s linear infinite;
  animation: loader 1.5s linear infinite;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
